<template>
  <div>
    <!-- 标签页和头部搜索 -->
    <div class="headSearch">
      <el-tabs type="card" @tab-click="changeTabs" :value="currentPanel">
        <el-tab-pane label="云平台" name="cloud">
          <div class="mt20">
            <el-form :inline="true">
              <el-form-item>
                <el-select
                  placeholder="产品端"
                  class="centerSelInput"
                  v-model="list.productSide"
                >
                  <el-option
                    v-for="item in productlist"
                    :key="item.value"
                    :label="item.product"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  placeholder="产品类型"
                  class="centerSelInput"
                  v-model="list.productType"
                >
                  <el-option
                    v-for="item in producttypelist"
                    :key="item.value"
                    :label="item.producttype"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <button
                  class="centerCzbtn ml20"
                  @click="getTableData(searchPage)"
                >
                  搜索
                </button>
              </el-form-item>
              <el-form-item>
                <button class="centerBtn resetbtn" @click="reset">重置</button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="自动驾驶" name="auto">
          <div class="mt20">
            <el-form :inline="true">
              <el-form-item>
                <el-cascader
                  clearable
                  v-model="selectCarType"
                  class="centerSelInput centerInput"
                  :options="carTypeOption"
                  :props="carTypeOptionParams"
                  :show-all-levels="false"
                  placeholder="请选择车辆类型"
                ></el-cascader>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <button
                  class="centerCzbtn ml20"
                  @click="getTableData(searchPage)"
                >
                  搜索
                </button>
              </el-form-item>
              <el-form-item>
                <button class="centerBtn resetbtn" @click="reset">重置</button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 表格 -->
    <Table
      :data1="tableData"
      :columns1="currentPanel == 'cloud' ? cloud_columns : auto_columns"
      :total="total"
      @getAjax="getTableData"
      :loading="loading"
      :page="list.page"
      :size="list.size"
      :isShowOverflow="false"
    />
    <!-- 下载对话框 -->
    <el-dialog title="下载附件" :visible.sync="downLoadDia" width="30%">
      <div v-for="(item, idx) in fileList" :key="idx">
        <el-descriptions direction="vertical" :colon="false">
          <el-descriptions-item
            content-class-name="download-filename"
            :contentStyle="{ 'text-align': 'left' }"
            >{{ item.fileName }}</el-descriptions-item
          >
          <el-descriptions-item
            content-class-name="download-filesize"
            :contentStyle="{ 'text-align': 'right' }"
            >{{ item.fileSize }}</el-descriptions-item
          >
          <el-descriptions-item
            content-class-name="download-downloadlink"
            :contentStyle="{ 'text-align': 'center' }"
            ><span
              class="operationA"
              @click="downloadfile(item.fileName, item.fileUrl)"
              >下载</span
            ></el-descriptions-item
          >
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from '@/components/Table.vue' //Table组件
import { _CarTypeAll } from '../../api/selCar' //获取车辆类型和车辆子类型
import {
  _CouldFindAll,
  _AutoFindAll,
  _Filedownload
} from '../../api/versionManagement'
import { filesizeToString, getTextWidth } from '../../until/helper'
import Clipboard from 'clipboard'

export default {
  components: {
    Table //Table组件
  },
  data() {
    return {
      tableData: [], //Table数据
      cloud_columns: [
        {
          label: '产品端',
          prop: 'productSideName'
        },
        {
          label: '产品类型',
          prop: 'productTypeName'
        },
        {
          label: '版本号',
          prop: 'versionNo'
        },
        {
          label: '版本内容',
          prop: 'versionContent',
          width: '400',
          render: (h, params) => {
            // 去除换行
            let str = params.row.versionContent.replace(/[\r\n]/g, '')
            let width = getTextWidth(str, 'el-table')
            if (width >= 290) {
              return h(
                'div',
                {
                  class: 'version-row'
                },
                [
                  h(
                    'div',
                    {
                      class: 'version-div'
                    },
                    str
                  ),
                  h(
                    'el-button',
                    {
                      class: 'check-btn',
                      props: {
                        type: 'text'
                      },
                      on: {
                        click: () => {
                          let wrapText = params.row.versionContent.replace(
                            /[\r\n]/g,
                            '<br/>'
                          )
                          this.viewMore(wrapText)
                        }
                      }
                    },
                    '查看详情'
                  )
                ]
              )
            } else {
              return h('span', str)
            }
          }
        },
        {
          label: '发布时间',
          prop: 'createTime',
          width: '150'
        },
        {
          label: '发布者',
          prop: 'createName'
        },
        {
          label: '操作项',
          width: '150',
          render: (h, params) => {
            return h('div', {}, [
              h(
                'span',
                {
                  class: 'operationA',
                  on: {
                    click: () => {
                      this.currentRow = params.row
                      this.fileList = []
                      if (this.currentRow.versionManagementAttachmentList) {
                        this.currentRow.versionManagementAttachmentList.forEach(
                          el => {
                            this.fileList.push({
                              fileName: el.fileName,
                              fileSize: filesizeToString(el.fileSize),
                              fileUrl: el.id
                            })
                          }
                        )
                      }

                      this.downLoadDia = true
                    }
                  }
                },
                '下载'
              ),
              h(
                'span',
                {
                  class: 'operationA tag-read',
                  style: {
                    'margin-left': '10px',
                    display:
                      (params.row.productSide == 1 ||
                        params.row.productSide == 2) &&
                      params.row.productType != 1
                        ? 'inline-block'
                        : 'none'
                  },
                  on: {
                    click: () => {
                      let text = ''
                      if (params.row.productSide == 1) {
                        text = window.urlConfig.baseUrl
                      } else {
                        text = window.location.host
                      }
                      let clipboard = new Clipboard('.tag-read', {
                        text: () => {
                          return text
                        }
                      })
                      clipboard.on('success', () => {
                        this.$message.success('复制成功!')
                        // 释放内存
                        clipboard.destroy()
                      })
                      clipboard.on('error', () => {
                        // 不支持复制
                        this.$message.warning('该浏览器不支持自动复制!')
                        // 释放内存
                        clipboard.destroy()
                      })
                    }
                  }
                },
                '复制访问链接'
              )
            ])
          }
        }
      ],
      auto_columns: [
        {
          label: '车辆类型',
          prop: 'carTypeName'
        },
        {
          label: '车辆子类型',
          prop: 'carTypeSonName'
        },
        {
          label: '版本号',
          prop: 'versionNo'
        },
        {
          label: '版本内容',
          prop: 'versionContent',
          width: '400',
          render: (h, params) => {
            // 去除换行
            let str = params.row.versionContent.replace(/[\r\n]/g, '')
            let width = getTextWidth(str, 'el-table')
            if (width >= 290) {
              return h(
                'div',
                {
                  class: 'version-row'
                },
                [
                  h(
                    'div',
                    {
                      class: 'version-div'
                    },
                    str
                  ),
                  h(
                    'el-button',
                    {
                      class: 'check-btn',
                      props: {
                        type: 'text'
                      },
                      on: {
                        click: () => {
                          let wrapText = params.row.versionContent.replace(
                            /[\r\n]/g,
                            '<br/>'
                          )
                          this.viewMore(wrapText)
                        }
                      }
                    },
                    '查看详情'
                  )
                ]
              )
            } else {
              return h('span', str)
            }
          }
        },
        {
          label: '发布时间',
          prop: 'createTime',
          width: '150'
        },
        {
          label: '发布者',
          prop: 'createName'
        },
        {
          label: '操作项',
          width: 200,
          render: (h, params) => {
            if (params.row.versionManagementAttachmentList.length == 3) {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'operationA',
                    on: {
                      click: () => {
                        this.currentRow = params.row
                        this.fileList = []
                        if (this.currentRow.versionManagementAttachmentList) {
                          this.currentRow.versionManagementAttachmentList.forEach(
                            el => {
                              this.fileList.push({
                                fileName: el.fileName,
                                fileSize: filesizeToString(el.fileSize),
                                fileUrl: el.id
                              })
                            }
                          )
                        }

                        this.downLoadDia = true
                      }
                    }
                  },
                  '下载附件'
                )
              ])
            } else {
              return h('div', [
                h(
                  'span',
                  {
                    class: 'operationA',
                    on: {
                      click: () => {
                        this.currentRow = params.row
                        this.fileList = []
                        if (this.currentRow.versionManagementAttachmentList) {
                          this.currentRow.versionManagementAttachmentList.forEach(
                            el => {
                              this.fileList.push({
                                fileName: el.fileName,
                                fileSize: filesizeToString(el.fileSize),
                                fileUrl: el.id
                              })
                            }
                          )
                        }

                        this.downLoadDia = true
                      }
                    }
                  },
                  '下载附件'
                ),
                h(
                  'span',
                  {
                    class: 'operationA tag-read',
                    style: {
                      'margin-left': '10px'
                    },
                    on: {
                      click: () => {
                        let text = params.row.netDiskUrl
                        let clipboard = new Clipboard('.tag-read', {
                          text: () => {
                            return text
                          }
                        })
                        clipboard.on('success', () => {
                          this.$message.success('复制成功!')
                          // 释放内存
                          clipboard.destroy()
                        })
                        clipboard.on('error', () => {
                          // 不支持复制
                          this.$message.warning('该浏览器不支持自动复制!')
                          // 释放内存
                          clipboard.destroy()
                        })
                      }
                    }
                  },
                  '复制程序包链接'
                )
              ])
            }
          }
        }
      ],
      currentPanel: 'cloud', //当前选中的Tab
      currentRow: null, //当前操作行
      downLoadDia: false,
      fileList: [], //下载列表

      selectCarType: [], //车辆类型选择value
      carTypeOption: [], //车辆类型数据源
      //车辆子类型设置
      carTypeOptionParams: {
        children: 'children',
        label: 'typeName',
        value: 'id',
        checkStrictly: true
      },
      //车辆子类型数据源
      carSubType: [],

      productlist: [
        { product: "东风悦享云服务平台", value: 1 },
        { product: "东风悦享数据分析平台", value: 2 },
        { product: "智能招车APP", value: 3 },
        { product: "安全员APP", value: 4 },
        { product: "CMS数据管理平台", value: 5 },
        { product: "自动驾驶工具链平台", value: 6 },
        { product: "台账管理平台", value: 7 },
      ], //产品端

      producttypelist: [
        { producttype: 'WEB', value: '3' },
        { producttype: 'APP(安卓)', value: '1' }
      ], //产品类型

      //分页和查询条件
      list: {
        page: 1,
        size: 10,
        productSide: null, //产品ID
        productType: null, //产品类型ID
        carTypeId: null, //车辆类型ID
        carTypeSonId: null, //车辆子类型ID
        startTime: null,
        endTime: null
      },
      searchPage: {
        limit: 10,
        page: 1
      },

      loading: false,
      date: [],
      total: 0
    }
  },
  mounted() {
    this.getTableData()
    this.getCarTypeOption()
  },
  methods: {
    /**
     * @desc 按照查询条件获取Table数据
     * @param pagination [Object] 分页设置
     */
    getTableData(pagination) {
      // 设置分页
      if (pagination) {
        if (pagination.page) {
          this.list.page = pagination.page
          this.list.size = pagination.limit
        }
      }
      // 设置日期
      if (this.date != null) {
        this.list.startTime = this.date[0]
        this.list.endTime = this.date[1]
      } else {
        this.list.startTime = ''
        this.list.endTime = ''
      }
      //设置车辆类型Id
      if (this.selectCarType) {
        if (this.selectCarType.length == 1) {
          this.list.carTypeId = this.selectCarType[0]
          this.list.carTypeSonId = null
        } else {
          this.list.carTypeId = this.selectCarType[0]
          this.list.carTypeSonId = this.selectCarType[1]
        }
      }
      this.getList()
    },

    /**
     * @desc 按tab获取数据
     */
    async getList() {
      this.loading = true
      if (this.currentPanel == 'cloud') {
        //云平台获取版本数据请求
        let { success, message, data } = await _CouldFindAll(this.list)
        if (success == true) {
          this.tableData = data.list
          this.total = data.total
        } else {
          this.$message.error(message)
        }
      } else if (this.currentPanel == 'auto') {
        //自动车辆获取版本数据请求
        let { success, message, data } = await _AutoFindAll(this.list)
        if (success == true) {
          this.tableData = data.list
          this.total = data.total
        } else {
          this.$message.error(message)
        }
      }
      this.loading = false
    },

    /**
     * @description 获取车辆类型和车辆子类型
     */
    async getCarTypeOption() {
      _CarTypeAll().then(res => {
        if (res.success == true) {
          //设置查询的车辆类型数据源
          this.carTypeOption = res.data.list
        }
      })
    },

    /**
     * @description 重置查询条件和Table数据
     */
    reset() {
      this.list = {
        page: 1,
        size: 10,
        productSide: null, //产品
        productType: null, //产品类型
        carTypeId: null, //车辆类型ID
        carTypeSonId: null, //车辆子类型ID
        startTime: null,
        endTime: null
      }
      this.total = 0
      this.date = null
      this.selectCarType = null
      this.getList()
    },

    downloadfile(filename, fileurl) {
      window.open(
        `${
          window.urlConfig.url
        }system-nacos/platformVersionManagement/download?attachmentId=${fileurl}&X-Token=${
          'Basic ' + window.localStorage.getItem('token')
        }`
      )
    },

    /**
     * @description tab 被选中时触发
     * @param currentpanel [Object] 被选中的标签 tab 实例
     */
    changeTabs(currentpanel) {
      if (currentpanel) this.currentPanel = currentpanel.name

      this.reset()
    },
    /**
     * @name: viewMore
     * @msg:
     * @param {string} str 需要显示的文字
     */
    viewMore(str) {
      this.$msgbox({
        title: '版本内容',
        showConfirmButton: false,
        message: `<div>${str}</div>`,
        dangerouslyUseHTMLString: true,
        closeOnClickModal: false,
        customClass: 'msgbox',
        callback: () => {}
      })
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-input__inner {
  border: 0px;
}

/deep/.el-tabs__content {
  overflow: visible;
}

/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: 0px solid #e4e7ed;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0px solid #e4e7ed;
}

/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  //border-bottom-color: #f5f8fa;
  border-bottom: 0px solid #e4e7ed;
  border-left: 0px solid #e4e7ed;
  border-right: 0px solid #e4e7ed;
}

/deep/.el-dialog__body {
  padding: 5px 20px 20px 30px;
}

.download-filename {
  width: 50%;
}

.download-filesize {
  width: 30%;
}

.download-downloadlink {
  width: 20%;
}

/deep/.version-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .version-div {
    max-width: 295px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  .check-btn {
    padding: 8px;
    border: 1px solid #409eff;
  }
}
</style>
<style>
.msgbox .el-message-box__content {
  max-height: 70vh;
  overflow-y: auto;
}
</style>
